import { Line } from 'vue-chartjs'

export default {
  extends: Line,
  props: {
    chartdata: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      default: () => {},
    },
    labels: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      baseOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [{
            gridLines: {
              color: 'rgba(0, 0, 0, 0.08)',
              zeroLineWidth: 0,
            },
            ticks: {
              callback: value => value.length > 10 ? `${value.slice(0, 10)}...` : value,
            },
          }],
          yAxes: [{
            gridLines: {
              color: 'rgba(0, 0, 0, 0.08)',
              zeroLineWidth: 0,
            },
            ticks: {
              callback: value => value.length > 10 ? `${value.slice(0, 10)}...` : value,
            },
          }],
        },
      },
    }
  },
  mounted() {
    this.renderLineChart()
  },
  computed: {
    computedChartData() { return this.chartdata },
  },
  methods: {
    renderLineChart() {
      this.renderChart({
        labels: this.labels,
        datasets: [
          {
            fill: false,
            lineTension: 0.3,
            borderWidth: 1,
            borderColor: 'rgba(42, 204, 204, 1)',
            pointRadius: 4,
            pointBackgroundColor: 'rgb(201, 242, 242)',
            pointBorderColor: 'rgba(42, 204, 204, 1)',
            data: this.computedChartData,
          },
        ],
      }, { ...this.baseOptions, ...this.options })
    },
  },
  watch: {
    chartdata() {
      this.$data._chart.update()
      this.renderLineChart()
    },
  },
}
